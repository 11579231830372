@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";


.container {
  text-align: center;
  padding: spacings.$XXL 0 spacings.$XXXL 0;
}

.header {
  @include typography.headlinesH4TypographyStyles;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH2TypographyStyles;
  }

  margin-bottom: spacings.$XL;
}

.title {
  @include typography.headlinesH4TypographyStyles;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH3TypographyStyles;
  }
}

.footer {
  margin-top: spacings.$XXXL;
}


.col {
  min-height: 240px;
  display: flex;
  justify-content: center;
  margin-bottom: spacings.$S;

  @include mediaqueries.mediumUp {
    margin-bottom: spacings.$L;
  }

  @include mediaqueries.largeUp {
    min-height: 340px;
  }
}

.card {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: spacings.$XXS;

  @include mediaqueries.mediumUp {
    width: 85%;
  }
}

.link {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  object-fit: contain;
  max-width: 230px;
  height: auto;
}
