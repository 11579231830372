@use "src/styles/theme" as *; $themeName: "fixtercore";
/* index.module.scss */
@use '$styles/variables/spacings';
@use '$styles/variables/colors';
@use '$styles/variables/radii';
@use '$styles/mediaqueries';
@use '$styles/tools/mixins/typography';

.container {
  text-align: center;
  padding: spacings.$XXL 0 spacings.$XXXL 0;
}

.header {
  margin: spacings.$M auto spacings.$XXL auto !important;

  p {
    @include typography.paragraphSTypography;

    @include mediaqueries.mediumUp {
      @include typography.headlinesH5TypographyStyles;
    }


    strong {
      font-weight: bold;
    }
  }

}

.article {
  max-width: 776px;
  margin: auto;
  border: 1px solid colors.$brandLightGray;
  border-radius: radii.$XS;
  padding: spacings.$S;

  @include mediaqueries.mediumUp {
    padding: spacings.$XL;
  }
}

.articleHeader {
  img {
    width: 147px;
  }
}

.articleHeaderText {
  @include typography.headlinesH5TypographyStyles;

  @include mediaqueries.mediumUp {
    @include typography.headlinesH3TypographyStyles;
  }

  margin: spacings.$XS 0 spacings.$M 0;

  strong {
    font-weight: 600;
  }
}

.content {
  p {
    @include typography.paragraphMTypography;
  }

  ol {
    counter-reset: getaround-counter;
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    @include typography.paragraphSTypography;
    font-weight: 700;
    color: colors.$purple500;

    @include mediaqueries.mediumUp {
      font-size: 17px;
    }

    li {
      counter-increment: getaround-counter;
      position: relative;

      &:before {
        content: counter(getaround-counter);
        display: inline-block;
        width: 70px;
        height: 70px;
        margin-right: spacings.$XS;
        padding: spacings.$M;
        vertical-align: middle;
        background: url('https://res.cloudinary.com/fxtr/image/upload/v1623766261/landing/nut_withborder_xbohof.svg') no-repeat;
        background-size: contain;
        background-position: center;
        @include typography.headlinesH6TypographyStyles;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}

.footer {
  margin-top: spacings.$XL;
}


.button {
  margin: auto;
  max-width: 250px;

  span {
    margin: auto;
    @include typography.paragraphLTypography;
  }
}
