@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/tools/mixins/typography";
@use "$styles/variables/colors";

.faqContent {
  padding-top: 24px;
  h1 {
    @include typography.headlinesH2TypographyStyles;
    text-align: center;
  }

  @include mediaqueries.smallUp {
    padding-top: 95px;
    h1 {
      text-align: left;
    }
  }
}

.faqMenuCard {
  display: none;
  @include mediaqueries.smallUp {
    display: block;
    margin-top: 26px;
    padding: 25px 12px 5px;
    margin-top: 40px;
  }
}

.vrmLookupFAQMobile {
  @include mediaqueries.mediumUp {
    display: none !important;
  }
  margin-bottom: 40px !important;
}

.vrmLookupFAQDesktop {
  display: none !important;
  @include mediaqueries.mediumUp {
    display: flex !important;
    margin-top: 56px !important;
    margin-bottom: 41px !important;
  }
}