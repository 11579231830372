@use "src/styles/theme" as *; $themeName: "fixtercore";
@use "$styles/variables/colors";
@use '$styles/theme' as *;

.component {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 8px;
  font-weight: 600;


  @if ($themeName == 'motrio') {
    color: colors.$purple500; //
  }
  @else {
    color: colors.$neutral800; //
  }

  @if ($themeName == 'fixtercore') {
    text-decoration: underline;
  }

  > .qvIcon {
    transition: transform 250ms ease-in-out;
  }
  &:hover {
    > .qvIcon {
      transform: translateX(8px);
    }
  }
  &.L {
    font-size: 18px;
    line-height: 24px;
    column-gap: 12px;
  }
  &.M {
    font-size: 16px;
    line-height: 20px;
    column-gap: 12px;
  }
  &.S {
    font-size: 14px;
    line-height: 18px;
    column-gap: 4px;
  }
  &.XS {
    font-size: 12px;
    line-height: 16px;
    column-gap: 4px;
  }
  &.disabled {
    color: colors.$neutral300 !important;
  }

  &.L,
  &.M,
  &.S,
  &.XS {
    @if ($themeName == 'fixtercore') {
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
    &.left {
      flex-direction: row-reverse;
    }
    &:not([href]),
    &[href=''] {
      cursor: inherit;
      &:hover,
      &:active {
        color: colors.$neutral800;
        text-decoration: underline;
      }
    }

    &.light {
      color: colors.$white;
      &[href='']:hover,
      &[href='']:active {
        color: colors.$white;
      }
    }
    &:hover {
      &:not([href='']) {
        color: colors.$purple500;
        &.light {
          color: colors.$neutral100;
        }
      }
    }
    &:active {
      &:not([href='']) {
        color: colors.$purple600;
        &.light {
          color: colors.$neutral200;
        }
      }
    }
  }
  &.L,
  &.M,
  &.S {
    letter-spacing: -0.01em;
  }
  &.L,
  &.M {
    &.left {
      &:hover {
        > .qvIcon {
          transform: rotate(180deg) translateX(8px);
        }
      }
      > .qvIcon {
        transform: rotate(180deg);
      }
    }
  }
  &.S,
  &.XS {
    &:hover {
      > .qvIcon {
        transform: scale(0.6) translateX(8px); // 9.6px
      }
    }
    &.left {
      &:hover {
        > .qvIcon {
          transform: scale(0.6) rotate(180deg) translateX(8px); // 9.6px
        }
      }
      > .qvIcon {
        transform: rotate(180deg) scale(0.6); // 9.6px
      }
    }
    > .qvIcon {
      transform: scale(0.6); // 9.6px
    }
  }

  /**
   * To remove after we finish the transition to the new design
   */
   &.fat,
   &.big,
   &.small {
    column-gap: 14px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: colors.$purple500;
    text-decoration: none;
     > .qvIcon {
       transform: scale(0.75); // 12px
     }
     &:hover {
       > .qvIcon {
         transform: scale(0.75) translateX(8px); // 12px
       }
     }
     &:focus {
      //  ${focusRing}
       border-radius: 6px;
     }
     &.disabled,
     &[href=''] {
       color: colors.$purple400 !important;
       font-weight: 600px;
     }
     &:not(.fat, :focus) {
       &::after {
         content: '';
         display: block;
         position: absolute;
         bottom: 8px;
         left: 8px;
         width: 0;
         height: 0;
       }
     }
     &:hover {
       &::after {
         width: calc(100% - 8px);
         height: 1px;
         @media (-webkit-device-pixel-ratio: 2), (min-resolution: 2dppx) {
           height: 1.5px;
         }
         background-color: currentColor;
       }
     }
   }
   &.fat {
     line-height: 28px;
     padding: 18px 20px;
     background-color: colors.$purple200;
     border-radius: 6px;
     &.color-bg {
       background-color: colors.$white;
       box-shadow: inset 0 0 0 2px colors.$purple500;
       @media (-webkit-device-pixel-ratio: 2), (min-resolution: 2dppx) {
         box-shadow: inset 0 0 0 1.5px colors.$purple500;
       }
     }
 
     &:hover {
       background-color: colors.$purple300;
       &.color-bg {
         background-color: colors.$purple200;
       }
     }
 
     &:active {
       background-color: colors.$purple200;
       &.color-bg {
         background-color: colors.$white;
       }
     }
     &.disabled,
     &[href=''] {
       background-color: colors.$purple200;
       &.color-bg {
         background-color: colors.$white;
         box-shadow: inset 0 0 0 2px colors.$purple400;
         @media (-webkit-device-pixel-ratio: 2), (min-resolution: 2dppx) {
           box-shadow: inset 0 0 0 1.5px colors.$purple400;
         }
       }
     }
   }
   &.big {
     font-size: 20px;
     font-weight: 600;
     padding: 6px 8px;
   }
 
   > :global(.Link__icon) {
     &.purple-icon {
       color: colors.$white;
       > svg {
         transform: scale(16 / 0.24 / 100);
         z-index: 1;
       }
 
       &::after {
         content: '';
         display: block;
         width: 24px;
         height: 24px;
         border-radius: 12px;
         position: absolute;
         background-color: colors.$brandPurple;
       }
     }
   }
}
