@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';
@use '$styles/variables/spacings';

.component {
  margin-top: spacings.$XXL;
  @include mediaqueries.mediumUp {
    margin-top: 56px;
  }

  &:first-child {
    margin-top: spacings.$S;
  }
  &.componentMode {
    margin-top: spacings.$M;
    margin-bottom: spacings.$M;
    @include mediaqueries.mediumUp {
      margin-top: spacings.$L;
      margin-bottom: 0;
    }
  }
}

.cards {
  display: grid;
  gap: spacings.$S;
  margin-top: 20px;

  @include mediaqueries.mediumUp {
    gap: spacings.$L;
  }
}

.twoColumns {
  grid-template-columns: repeat(2, 1fr);
}

.threeColumns {
  grid-template-columns: repeat(2, 1fr);

  @include mediaqueries.mediumUp {
    grid-template-columns: repeat(3, 1fr);
  }
}

.heading {
  color: colors.$neutral600;
  text-align: center;
  @include typography.headlinesH6TypographyStyles;
  margin-bottom: spacings.$S;
  @include mediaqueries.smallUp {
    @include typography.headlinesH4TypographyStyles;
    margin-bottom: spacings.$M;
  }
}
