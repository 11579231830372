@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/mediaqueries';
@use "$styles/variables/colors";
@use "$styles/variables/fonts";
@use "$styles/variables/elevation";
@use '$styles/variables/spacings';

.component {
    z-index: elevation.$top;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.primary {
    background: linear-gradient(90deg, #BF194E -15.28%, #E12060 27.97%, #DA215E 71.23%, #9E1C46 114.48%, #BF194E 114.48%);
    color: colors.$white;
    padding: 16px 20px;
    align-items: center;
    font: fonts.$labelM;
    @include mediaqueries.mediumUp {
        font: fonts.$headlinesH6;
    }
}

.secondary {
    background: colors.$red100;
    color: colors.$red400;
    padding: spacings.$XXS;
    font: fonts.$paragraphXS;
    @include mediaqueries.mediumUp {
        padding: spacings.$XS;
        font: fonts.$paragraphM;
        font-weight: 500;
    }
}
