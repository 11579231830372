@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/colors';
@use '$styles/tools/mixins/typography';
@use '$styles/variables/spacings';

.divider {
  display: flex;
  flex-direction: row;
  color: colors.$neutral400;
  margin: 10px 0;
  gap: 3px;
}

.line {
  border-top: 1px solid colors.$neutral200;
  width: 100%;
  margin: auto;
}

.lineLight {
  border-top: 1px solid colors.$white;
  width: 100%;
  margin: auto;
}

.callUs {
  text-align: center;

  p {
    @include typography.labelMTypographyStyles;
    margin-top: 0;
  }
}

.ctaOverviewPage {
  position: relative;
  span {
    display: block;
    text-align: center;
  }
}

.heading {
  @include typography.headlinesH5TypographyStyles;
  margin-bottom: spacings.$S;
  span {
    color: colors.$brandYellow;
  }
}

.lightTextSeparator {
  color: colors.$white;
}