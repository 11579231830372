@use "src/styles/theme" as *; $themeName: "fixtercore";
@use '$styles/variables/spacings';
@use '$styles/tools/mixins/typography';
@use '$styles/mediaqueries';

.outerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacings.$XXXL;
}

.container {
  max-width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacings.$XS;

  @include mediaqueries.mediumUp {
    max-width: 600px;
  }
}

.heading {
  @include typography.headlinesH3TypographyStyles;
  text-align: center;
}

.contents {
  display: flex;
  flex-direction: column;
  gap: spacings.$M;
}

.body {
  text-align: center;
  @include typography.paragraphLTypography;
}

.ctas {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: spacings.$M;

  @include mediaqueries.mediumUp {
    flex-direction: row;
  }
}

.ctaSecondary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: spacings.$S;
}
